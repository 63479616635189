/* If you are wondering about font-display: https://styled-components.com/docs/faqs#how-do-i-fix-flickering-text-after-server-side-rendering */
@font-face {
  font-family: 'ElkjopSans';
  src: url('/fonts/ElkjopSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}
@font-face {
  font-family: 'ElkjopSans';
  src: url('/fonts/ElkjopSans-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
}
@font-face {
  font-family: 'ElkjopSans';
  src: url('/fonts/ElkjopSans-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
}
@font-face {
  font-family: 'ElkjopSans';
  src: url('/fonts/ElkjopSans-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: 'ElkjopSansGX';
  src: url('/fonts/ElkjopSansGX.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: 'ElkjopHeadline';
  src: url('/fonts/ElkjopHeadline-Regular.otf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}
@font-face {
  font-family: 'ElkjopHeadline';
  src: url('/fonts/ElkjopHeadline-Medium.otf') format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
}
@font-face {
  font-family: 'ElkjopHeadline';
  src: url('/fonts/ElkjopHeadline-Bold.otf') format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}
